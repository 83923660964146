import { handleQueryResolve } from '../utils'

export default function () {
  return this.query(`
        SELECT
            patient.Id as PatientID
        FROM
            DBA.Patient as patient
        WHERE
            patient.Id = 1
    `)
    .then(handleQueryResolve)
}
